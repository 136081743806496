<template>
  <div class="resource_detail public_main_bgc">
    <StudioIndex v-if="is_show_head == 1"></StudioIndex>

    <div class="w_layout">
      <!-- 面包屑 -->
      <div class="bread">
        <div class="bread_content">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>四名工作室管理</el-breadcrumb-item>
            <el-breadcrumb-item>资源列表</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="font_orange">资源详情</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="fs content">
        <div class="about" v-if="other.audit_status == 1 && joinStudio">
          <el-button size="mini" type="primary" @click="PassWay(4)">审核通过</el-button>
          <el-button size="mini" type="danger" @click="noPass">审核不通过</el-button>
        </div>
        <!-- //不通过弹框 -->
        <el-dialog title="不通过的理由" :visible.sync="dialogVisible" width="30%">
          <el-input type="textarea" :rows="5" placeholder="请输入审核不通过的理由" resize="none" v-model="form1.refusal">
          </el-input>

          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="PassWay(5)">确认</el-button>
          </span>
        </el-dialog>
        <!-- 左 -->
        <div class="left">
          <div class="fsbc pb24">
            <p class="fs20 fw600" style="
                width: 750px;
                line-height: 26px;
                white-space: pre-line;
                overflow: hidden;
                -webkit-line-clamp: 1;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              ">
              {{ other.title }}
            </p>
            <div class="fc mybtn">
              <!-- 预览压缩包 -->
              <div @click="onPreviewComprimer" class="cursor" v-if="type == 5">
                <img src="@/assets/images/preview.png" alt="" />
                <span>预览压缩包</span>
              </div>
              <!-- 收藏 -->
              <div @click="onCellerction" class="ml30 cursor">
                <img v-if="other.is_collect" src="@/assets/images/shoucang_had.png" alt="" style="margin-top: 0" />
                <img v-else src="@/assets/images/shoucang.png" alt="" />
                <span>收藏</span>
              </div>
              <div class="ml30 cursor download" @click="onDownLoad">
                下载到本地
              </div>
            </div>
          </div>
          <el-row>
            <el-col :span="3">
              <div>
                <span>大小：</span>
                <span>{{ detail.size | fileSizeFilter }}</span>
              </div>
            </el-col>
            <el-col :span="3">
              <div>
                <span>下载量：</span>
                <span>{{ other.download_count }}</span>
              </div>
            </el-col>
            <el-col :span="5">
              <div>
                <span>时间：</span>
                <span>{{ detail.create_time }}</span>
              </div>
            </el-col>
            <el-col :span="7">
              <div>
                <span>分类：</span>
                <span>{{ other.type_name }}
                  <template v-if="other.pharse_str">，{{ other.pharse_str }}</template>
                  <template v-if="other.subject_str">，{{ other.subject_str }}</template>
                  <template v-if="other.grade_str">，{{ other.grade_str }}</template>
                </span>
              </div>
            </el-col>
            <el-col :span="4">
              <p class="inf_item_item" style="color: rgb(240, 95, 95);">
                {{ other.audit_status == 1 ? '待审核' : other.audit_status == 5 ? '未通过' : '' }}
                <el-popover v-if="other.audit_status == 5" placement="top-start" title="" width="200" trigger="hover"
                  :content="other.refusal">
                  <i style="color: rgb(240, 95, 95);" slot="reference" class="el-icon-question"></i>
                </el-popover>
              </p>
            </el-col>
          </el-row>
          <!-- 文件预览 -->
          <div class="preview_file">
            <!-- 图片预览 -->
            <div v-if="type === 1">
              <el-image style="width: 100%; height: 100%; display: block" :src="detail.url" fit="fill"></el-image>
            </div>
            <!-- 在线文档 -->
            <div v-else-if="type === 2" style="width: 100%; height: 740px">
              <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' +
                detail.url
                " style="width: 100%; height: 100%"></iframe>
            </div>
            <!-- 视频 -->
            <div v-else-if="type === 3">
              <video :src="detail.url" type="video/mp4" poster="false.png" autoplay="autoplay" controls="controls"
                loop="-1" style="width: 100%; height: 100%">
                <p>你的浏览器不支持video标签.</p>
              </video>
            </div>
            <!-- pdf -->
            <div v-else-if="type === 4" style="padding-top: 20px">
              <div class="fcc">
                <div style="margin-bottom: 15px; text-align: right">
                  <el-button type="primary" size="small" @click.stop="previousPage">上一页</el-button>
                  <el-button type="primary" size="small" @click.stop="nextPage">下一页</el-button>
                  <span>当前第{{ pdfPage }}页 / 共{{ pageCount }}页</span>
                </div>
              </div>

              <div>
                <pdf :src="detail.url" :page="pdfPage" @num-pages="pageCount = $event" @page-loaded="pdfPage = $event"
                  style="width: 100%; height: 720px; overflow-y: auto"></pdf>
              </div>
            </div>
            <!-- 压缩包 -->
            <div v-else-if="type === 5" style="height: 600px; text-align: center; line-height: 600px">
              点击右上角'预览压缩包'进行压缩包预览
            </div>
            <div v-else style="height: 600px; text-align: center; line-height: 600px">
              <span>暂不支持查看该类型文件</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {
  ResourceDetail,
  ResourceRecomments,
  CollerctionResource,
} from "@/api/teacherStudio";
import { onShowImages3 } from "@/utils/utils";
import pdf from "vue-pdf";
import { isHasStudioIdFn } from "@/utils/utils.js";
export default {
  components: { pdf },
  data() {
    return {
      dialogVisible: false,
      joinStudio: false,
      is_show_head: null,
      type: 1,
      pdfPage: 1,
      pageCount: 0,
      detail: {},
      other: {},
      //   ComprimerUrl: "",
      resourceList: [],
      // 详情id
      id: "",
      // 资源id
      resource_id: "",
      teaching_studio_id: "",
      form1: {
        audit_status: "", //2 审核通过 3审核不通过
        teaching_studio_id: localStorage.getItem("studioId"),
        id: "", //审核id
        refusal: "",
      },
    };
  },
  methods: {
    //不通过
    noPass() {
      this.form1.id = this.$route.query.id; //审核id
      this.dialogVisible = true;
    },
    //通过
    PassWay(type) {
      this.form1.audit_status = type; //2 审核通过 3审核不通过
      this.form1.id = this.$route.query.id; //审核id
      this.$axios
        .put("TeachingStudioResource/statusResource", this.form1)
        .then(() => {
          if (type == 1) {
            this.$message.success("审核通过");
          } else {
            this.$message.success("审核不通过");
            this.dialogVisible = false;
          }
          this.$router.go(0)
        });
    },
    // 切换资源
    onTrigger(id) {
      this.id = id;
      this.onGetDetail();
    },
    onShowImages3,
    // 收藏
    onCellerction() {
      CollerctionResource({
        id: localStorage.getItem("id"),
        resource_id: this.id,
        teaching_studio_id: this.teaching_studio_id,
        user_id: localStorage.getItem("id"),
        teaching_studio_user_resource_id: this.id,
      }).then((res) => {
        this.$message.success(res.msg);
        if (res.msg == "收藏成功") {
          this.$set(this.other, "is_collect", 1);
        } else {
          this.$set(this.other, "is_collect", 0);
        }
      });
    },
    //PDF改变页数
    previousPage() {
      var p = this.pdfPage;
      p = p > 1 ? p - 1 : this.pageCount;
      this.pdfPage = p;
    },
    nextPage() {
      var p = this.pdfPage;
      p = p < this.pageCount ? p + 1 : 1;
      this.pdfPage = p;
    },
    //判断是否图片
    isAssetTypeAnImage(ext) {
      return ["png", "jpg", "jpeg", "bmp", "gif", "webp", "psd", "svg", "tiff"].includes(ext.toLowerCase());
    },
    // 判断是否是文档
    isAssetTypeAnword(ext) {
      return ["epub", "doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(ext.toLowerCase());
    },
    // 判断是否是视频
    isAssetTypehshipin(ext) {
      return ["avi", "wmv", "mpeg", "mp4", "m4v", "flv", "f4v", "rmvb", "rm", "3gp", "vob"].includes(ext.toLowerCase());
    },
    // 判断是否是pdf
    isAssetTypehpdf(ext) {
      return ["pdf"].includes(ext.toLowerCase());
    },
    // 判断是否是压缩包
    isComprimer(ext) {
      return ["zip", "rar", "7z", "jgz"].includes(ext.toLowerCase());
    },
    // 下载
    onDownLoad() {
      this.$axios.get("TeachingStudio/resource/down_resource", { params: { id: this.id, }, }).then(() => {
        window.location.href = this.detail.url;
        this.onGetDetail();
      });
    },
    // 预览压缩包
    onPreviewComprimer() {
      window.open(this.other.view_url, "_blank");
    },
    onGetDetail() {
      ResourceDetail({ id: this.id, teaching_studio_id: this.teaching_studio_id, }).then((res) => {
        this.detail = res.data.resource_data;
        this.other = res.data;
        if (this.isAssetTypeAnImage(this.detail.ext)) {
          this.type = 1; // 图片
        } else if (this.isAssetTypeAnword(this.detail.ext)) {
          this.type = 2; // 文档
        } else if (this.isAssetTypehshipin(this.detail.ext)) {
          this.type = 3; // 视频
        } else if (this.isAssetTypehpdf(this.detail.ext)) {
          this.type = 4; // pdf
        } else if (this.isComprimer(this.detail.ext)) {
          this.type = 5; // 压缩包
        } else {
          this.type = 6; // 其他
        }
      });
    },
  },
  created() {
    this.joinStudio = JSON.parse(localStorage.getItem("joinStudio"))
    if (this.$route.query.is_show_head) {
      if (isHasStudioIdFn(this)) {
        this.is_show_head = this.$route.query.is_show_head
      };
    };
    let { id } = this.$route.query;
    this.resource_id = this.$route.query.resource_id;
    this.id = id;
    this.teaching_studio_id = localStorage.getItem("studioId");
    // 资源详情推荐列表
    ResourceRecomments({
      teaching_studio_user_resource_id: id,
      teaching_studio_id: id,
    }).then((res) => {
      this.resourceList = res.data.data;
    });
    this.onGetDetail();
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
  line-height: 1;
}

.resource_detail {
  padding-bottom: 20px;

  .bread {
    .bread_content {
      height: 48px;
      margin-top: 0;
    }

    .bread_hengxian {
      margin-bottom: 0;
    }
  }

  .content {
    position: relative;

    .about {
      position: absolute;
      right: 23px;
      top: 3px;
      background: #f00;
      padding: 10px;
      background: rgba(234, 241, 247, 1);
      border-radius: 0 8px 0 8px;
    }

    .left {
      width: 100%;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0px 4px 7px 0px #f5f5f5;
      margin-right: 20px;
      padding: 60px 32px 50px;

      .download {
        width: 108px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        background: #3489fe;
        border-radius: 2px;
        color: #fff;
      }

      .mybtn {
        img {
          vertical-align: text-top;
          margin-top: 3px;
          margin-right: 5px;
        }
      }

      .el-row {
        color: #666;
        padding-bottom: 36px;
      }

      .preview_file {
        border: 1px solid #ececec;
        border-radius: 2px;
        /* max-height: 740px; */
      }
    }

    .recomment {
      width: 335px;
      background: #ffffff;
      border-radius: 6px;
      padding: 30px 24px 0;

      h2 {
        padding-bottom: 20px;
      }

      li {
        display: flex;
        align-items: center;
        margin-bottom: 22px;
        border-bottom: 1px dashed #ececec;
        padding-bottom: 20px;

        &:last-child {
          border-bottom: none;
        }

        p {
          line-height: 24px;
          white-space: pre-line;
          overflow: hidden;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }

        .imgs {
          font-size: 20px;
          margin: 6px 10px 0 0;
        }
      }
    }
  }
}
</style>